<template>
  <div class="animation-fadeIn animation-50 animation-once">
    <div
      class="border-l-none border-r-none sm:border group relative mb-1 flex cursor-pointer items-center rounded-sm border-neutral-50 bg-white transition-all duration-200 hover:border-neutral-200 sm:items-stretch"
    >
      <div
        v-if="wasRecentlyUpdated"
        class="absolute h-full w-1 bg-primary-50"
      ></div>
      <div
        class="w-9/12 p-2 py-4 pl-5 sm:w-6/12 sm:pl-4 md:pr-8 xl:pr-16"
        @click="open"
      >
        <div class="flex items-center">
          <label
            v-if="canSelect && $can('write:assetPackages')"
            :class="[
              'relative mr-4 hidden h-5 w-5 cursor-pointer items-center justify-center rounded-sm border-2 border-neutral-100 transition-all duration-200 hover:border-neutral-300  lg:flex',
              {
                'border-primary-50 bg-primary-50 hover:border-primary-50':
                  isAssetSelected(asset),
              },
            ]"
            @click.stop
          >
            <span class="absolute h-12 w-12"></span>
            <input
              :checked="isAssetSelected(asset)"
              type="checkbox"
              class="invisible absolute"
              @change="toggleSelectAsset(asset)"
            />
            <IconCheckbox
              :class="[
                {
                  'opacity-0': !isAssetSelected(asset),
                },
              ]"
            />
          </label>
          <div
            class="border relative mr-4 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-sm border-neutral-50 bg-neutral-0 text-neutral-300"
          >
            <AssetsPreview
              :asset-filetype-name="asset.file.type"
              :asset-url="asset.file.url"
            />
          </div>
          <div class="min-w-0" @click="open">
            <div class="truncate text-sm font-medium">
              {{ asset.name }}
            </div>
            <div class="truncate text-xs text-neutral-400">
              <span v-for="(tag, index) in asset.tags" :key="index">
                {{ tag.name
                }}<span v-if="index < asset.tags.length - 1">,</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="w-3/12 p-2 py-4 pr-4 text-right sm:w-4/12 sm:text-left lg:w-2/12"
        @click="open"
      >
        <div class="truncate text-xs sm:text-sm">
          {{ asset.file.type }}
        </div>
        <div class="truncate text-xs text-neutral-400">
          {{ humanReadableSize }}
        </div>
      </div>
      <div
        class="flex-grow hidden w-2/12 items-center p-2 py-4 lg:flex"
        @click="open"
      >
        <div
          v-if="wasRecentlyUpdated"
          class="mr-2 h-1 w-1 rounded-full bg-primary-600"
        ></div>
        <div
          :class="[
            'truncate text-xs',
            { 'text-neutral-400': !wasRecentlyUpdated },
          ]"
        >
          {{
            $t("components.brandMarketing.assets.assetsRow.expireDate", {
              expireDate: expireDate,
            })
          }}
        </div>
      </div>
      <OverflowMenu
        v-if="menuItems.length > 0"
        class="hidden items-center justify-end p-2 py-4 pr-4 sm:flex"
        :items="menuItems"
      />
      <div
        v-else
        class="hidden items-center justify-end p-2 py-4 pr-4 opacity-0 transition-all duration-300 group-hover:opacity-100 sm:flex"
      >
        <a
          :href="asset.file.url"
          target="_blank"
          class="text-neutral-300 transition-all duration-200 hover:text-neutral-800"
          download
          @click.stop
        >
          <IconDownload />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import humanReadableBytes from "@/utils/humanReadableBytes";
import AssetsPreview from "@/components/brand-marketing/assets/AssetsPreview";
import OverflowMenu from "@/components/common/OverflowMenu";

export default {
  components: {
    AssetsPreview,
    OverflowMenu,
  },
  props: {
    asset: {
      type: Object,
      required: true,
    },
    canSelect: {
      type: Boolean,
      default: false,
    },
    isAssetSelected: {
      type: Function,
      default: undefined,
    },
    toggleSelectAsset: {
      type: Function,
      default: undefined,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      wasRecentlyUpdated: false,
    };
  },

  computed: {
    expireDate() {
      const date = new Date(this.asset.expireDate);
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return new Intl.DateTimeFormat("en-US", options).format(date);
    },
    humanReadableSize() {
      return humanReadableBytes(this.asset.file.size);
    },
  },

  mounted() {
    this.wasRecentlyUpdated = this.lessThanSecondsAgo(this.asset.updatedAt, 10);
  },

  methods: {
    open() {
      this.$emit("open", this.asset);
      this.wasRecentlyUpdated = false;
    },
    lessThanSecondsAgo(date, secondsAgo) {
      return (Date.now() - date) / 1000 < secondsAgo;
    },
  },
};
</script>
