<template>
  <ModalSide @close="closeModal">
    <template v-slot:tabs>
      <button
        v-if="!editMode"
        class="border-r focus:outline-none transition relative h-full border-neutral-50 px-8 text-xs font-medium text-neutral-900 duration-200 hover:text-neutral-900"
      >
        <span>
          {{
            $t("components.brandMarketing.assets.assetsModalDetails.details")
          }}
        </span>
        <span class="absolute inset-0 border-b-2 border-primary-500"></span>
      </button>

      <button
        v-if="editMode"
        class="border-r focus:outline-none transition relative h-full border-neutral-50 px-8 text-xs font-medium text-neutral-900 duration-200 hover:text-neutral-900"
      >
        <span>
          {{
            $t(
              "components.brandMarketing.assets.assetsModalDetails.editDetails",
            )
          }}
        </span>
        <span class="absolute inset-0 border-b-2 border-primary-500"></span>
      </button>
    </template>

    <template v-slot:content>
      <ModalSideContent v-if="!editMode" class="h-64 bg-neutral-0 md:p-10">
        <div
          class="relative flex h-full w-full items-center justify-center text-neutral-300"
        >
          <AssetsPreview
            :asset-filetype-name="asset.file.type"
            :asset-url="asset.file.url"
            :large-preview="true"
          />
        </div>
      </ModalSideContent>

      <ModalSideContent v-if="!editMode">
        <div class="mb-4 flex items-center">
          <div class="flex-grow text-xl font-medium">
            {{ asset.name }}
          </div>
          <div
            v-if="canEdit && $can('write:assets')"
            class="ml-4 hidden flex-shrink-0 lg:block"
          >
            <BaseButton
              size="medium"
              variant="outline"
              @buttonClick="openEditMode"
            >
              <template #iconCenter>
                <IconEdit />
              </template>
            </BaseButton>
          </div>
        </div>
        <div class="mb-5 flex flex-wrap text-sm">
          <div class="mb-2 flex w-1/2">
            <div class="w-5/12">
              <span class="text-neutral-400">
                {{
                  $t(
                    "components.brandMarketing.assets.assetsModalDetails.fileType",
                  )
                }}
              </span>
            </div>
            <div class="w-7/12">{{ asset.file.type }}</div>
          </div>
          <div class="mb-2 flex w-1/2">
            <div class="w-5/12">
              <span class="text-neutral-400">
                {{
                  $t(
                    "components.brandMarketing.assets.assetsModalDetails.fileSize",
                  )
                }}
              </span>
            </div>
            <div class="w-7/12">{{ humanReadableSize }}</div>
          </div>
          <div class="mb-2 flex w-1/2">
            <div class="w-5/12">
              <span class="text-neutral-400">
                {{
                  $t(
                    "components.brandMarketing.assets.assetsModalDetails.updatedOn",
                  )
                }}
              </span>
            </div>
            <div class="w-7/12">{{ updatedAt() }}</div>
          </div>
          <div class="mb-2 flex w-1/2">
            <div class="w-5/12">
              <span class="text-neutral-400">
                {{
                  $t(
                    "components.brandMarketing.assets.assetsModalDetails.expires",
                  )
                }}
              </span>
            </div>
            <div class="w-7/12">{{ expireDate() }}</div>
          </div>
        </div>
        <div class="copy-link-button-wrapper flex flex-wrap">
          <a
            :href="asset.file.url"
            download
            class="mr-4 mb-4 inline-flex justify-center rounded-sm bg-primary-600 py-3 pr-5 pl-3 text-sm font-medium text-white shadow-inner transition-all duration-200 hover:bg-primary-700 md:mb-0"
          >
            <IconDownload />
            <span class="ml-2">
              {{
                $t(
                  "components.brandMarketing.assets.assetsModalDetails.downloadFile",
                )
              }}
            </span>
          </a>
        </div>
      </ModalSideContent>

      <ModalSideContent v-if="!editMode">
        <div class="mb-4 font-medium">
          {{
            $t(
              "components.brandMarketing.assets.assetsModalDetails.relatedTags",
            )
          }}
        </div>
        <div class="flex flex-wrap">
          <button
            v-for="tag in asset.tags"
            :key="tag.name"
            class="border focus:outline-none mr-2 mb-2 rounded-sm border-neutral-50 bg-neutral-50 px-2 py-1 text-xs font-medium transition-all duration-200 hover:border-neutral-100"
            @click="clickedTag(tag)"
          >
            {{ tag.name }}
          </button>
          <div v-if="asset.tags.length === 0" class="text-sm text-neutral-600">
            {{
              $t(
                "components.brandMarketing.assets.assetsModalDetails.noRelatedTags",
              )
            }}
          </div>
        </div>
      </ModalSideContent>

      <ModalSideContent v-if="editMode" class="flex-grow overflow-y-scroll">
        <div
          v-if="formError"
          class="mb-4 rounded-sm border-l-2 border-danger-100 bg-danger-50 px-3 py-2 text-xs font-medium text-danger-500"
        >
          {{ formError }}
        </div>
        <AssetsForm
          :current-asset="asset"
          :categories="categories"
          :tags="tags"
          @updateName="updateName"
          @updateCategoryUuid="updateCategoryUuid"
          @updateTags="updateTags"
          @updateExpireDate="updateExpireDate"
        />
      </ModalSideContent>

      <ModalSideActions v-if="editMode" class="justify-between">
        <div class="text-sm text-neutral-900">
          <div v-if="!deleteWarningVisible">
            <button
              class="focus:outline-none font-medium"
              @click="showDeleteWarning"
            >
              {{ $t("global.delete") }}
            </button>
          </div>
          <div v-else>
            {{ $t("global.areYouSure") }}
            <button
              class="focus:outline-none ml-2 inline-block font-medium"
              @click="deleteAsset"
            >
              {{ $t("global.yes") }}
            </button>
            <button
              class="focus:outline-none ml-2 inline-block font-medium"
              @click="hideDeleteWarning"
            >
              {{ $t("global.no") }}
            </button>
          </div>
        </div>
        <div class="flex">
          <BaseButton
            :text="$t(`global.cancel`)"
            size="medium"
            variant="outline"
            @buttonClick="closeEditMode"
          ></BaseButton>

          <BaseButton
            :text="$t(`global.saveChanges`)"
            size="medium"
            variant="fill"
            class="ml-2"
            @buttonClick="saveChanges"
          >
          </BaseButton>
        </div>
      </ModalSideActions>
    </template>
  </ModalSide>
</template>

<script>
import AssetsForm from "@/components/brand-marketing/assets/AssetsForm";
import AssetsPreview from "@/components/brand-marketing/assets/AssetsPreview";
import ModalSide from "@/components/common/ModalSide";
import ModalSideContent from "@/components/common/ModalSideContent";
import ModalSideActions from "@/components/common/ModalSideActions";

import humanReadableBytes from "@/utils/humanReadableBytes";

import UPDATE_ASSET from "@/graphql/UpdateAsset.gql";
import DELETE_ASSET from "@/graphql/DeleteAsset.gql";
import FIND_ASSETS from "@/graphql/FindAssets.gql";

export default {
  components: {
    AssetsForm,
    AssetsPreview,
    ModalSide,
    ModalSideContent,
    ModalSideActions,
  },

  props: {
    asset: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    tags: {
      type: Array,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      editMode: false,
      deleteWarningVisible: false,
      formError: null,

      editedName: this.asset.name,
      editedCategoryUuid: this.asset.category.uuid,
      editedTagsUuids: this.asset.tags.map((tag) => tag.uuid),
      editedExpireDate: this.asset.expireDate,
    };
  },
  computed: {
    humanReadableSize() {
      return humanReadableBytes(this.asset.file.size);
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    updatedAt() {
      const date = new Date(parseInt(this.asset.updatedAt));
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return new Intl.DateTimeFormat("en-US", options).format(date);
    },
    expireDate() {
      const date = new Date(this.asset.expireDate);
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return new Intl.DateTimeFormat("en-US", options).format(date);
    },
    deleteAsset() {
      this.$apollo
        .mutate({
          mutation: DELETE_ASSET,
          variables: {
            uuid: this.asset.uuid,
          },
          update: (store, { data: { deletedAsset } }) => {
            const data = store.readQuery({
              query: FIND_ASSETS,
              variables: {
                categories: [],
                tags: [],
                fileTypes: [],
              },
            });

            const index = data.assets.findIndex(
              (asset) => asset.uuid == deletedAsset.uuid,
            );

            if (index !== -1) {
              data.assets.splice(index, 1);

              store.writeQuery({
                query: FIND_ASSETS,
                variables: {
                  categories: [],
                  tags: [],
                  fileTypes: [],
                },
                data,
              });

              this.$emit("resetFilters");
              this.$emit("close");
            }
          },
        })
        .catch((error) => {
          this.formError = JSON.stringify(error.message); //@TODO: replace with proper error logging later and remove formError cause it's never rendered
        });
    },
    clickedTag(tag) {
      this.$emit("clickedTag", tag);
      this.$emit("close");
    },
    openEditMode() {
      this.editMode = true;
    },
    closeEditMode() {
      this.editMode = false;
      this.deleteWarningVisible = false;
      this.formError = null;
    },
    showDeleteWarning() {
      this.deleteWarningVisible = true;
    },
    hideDeleteWarning() {
      this.deleteWarningVisible = false;
    },
    saveChanges() {
      this.$apollo
        .mutate({
          mutation: UPDATE_ASSET,
          variables: {
            uuid: this.asset.uuid,
            name: this.editedName,
            categoryUuid: this.editedCategoryUuid,
            tags: this.editedTagsUuids,
            expireDate: this.editedExpireDate,
          },
        })
        .then(() => {
          this.closeEditMode();
        })
        .catch((error) => {
          this.formError = JSON.stringify(error.message);
        });
    },
    updateName(assetName) {
      this.editedName = assetName;
    },
    updateCategoryUuid(assetCategoryUuid) {
      this.editedCategoryUuid = assetCategoryUuid;
    },
    updateTags(tagsUuids) {
      this.editedTagsUuids = tagsUuids;
    },
    updateExpireDate(assetExpireDate) {
      this.editedExpireDate = assetExpireDate;
    },
  },
};
</script>

<style>
.copy-link-button-wrapper .tippy-content {
  @apply rounded-sm bg-neutral-800 py-1 px-2 text-2xs font-medium text-white !important;
}
</style>
