var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"animation-fadeIn animation-50 animation-once"},[_c('div',{staticClass:"border-l-none border-r-none sm:border group relative mb-1 flex cursor-pointer items-center rounded-sm border-neutral-50 bg-white transition-all duration-200 hover:border-neutral-200 sm:items-stretch"},[(_vm.wasRecentlyUpdated)?_c('div',{staticClass:"absolute h-full w-1 bg-primary-50"}):_vm._e(),_c('div',{staticClass:"w-9/12 p-2 py-4 pl-5 sm:w-6/12 sm:pl-4 md:pr-8 xl:pr-16",on:{"click":_vm.open}},[_c('div',{staticClass:"flex items-center"},[(_vm.canSelect && _vm.$can('write:assetPackages'))?_c('label',{class:[
            'relative mr-4 hidden h-5 w-5 cursor-pointer items-center justify-center rounded-sm border-2 border-neutral-100 transition-all duration-200 hover:border-neutral-300  lg:flex',
            {
              'border-primary-50 bg-primary-50 hover:border-primary-50':
                _vm.isAssetSelected(_vm.asset),
            } ],on:{"click":function($event){$event.stopPropagation();}}},[_c('span',{staticClass:"absolute h-12 w-12"}),_c('input',{staticClass:"invisible absolute",attrs:{"type":"checkbox"},domProps:{"checked":_vm.isAssetSelected(_vm.asset)},on:{"change":function($event){return _vm.toggleSelectAsset(_vm.asset)}}}),_c('IconCheckbox',{class:[
              {
                'opacity-0': !_vm.isAssetSelected(_vm.asset),
              } ]})],1):_vm._e(),_c('div',{staticClass:"border relative mr-4 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-sm border-neutral-50 bg-neutral-0 text-neutral-300"},[_c('AssetsPreview',{attrs:{"asset-filetype-name":_vm.asset.file.type,"asset-url":_vm.asset.file.url}})],1),_c('div',{staticClass:"min-w-0",on:{"click":_vm.open}},[_c('div',{staticClass:"truncate text-sm font-medium"},[_vm._v(" "+_vm._s(_vm.asset.name)+" ")]),_c('div',{staticClass:"truncate text-xs text-neutral-400"},_vm._l((_vm.asset.tags),function(tag,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(tag.name)),(index < _vm.asset.tags.length - 1)?_c('span',[_vm._v(",")]):_vm._e()])}),0)])])]),_c('div',{staticClass:"w-3/12 p-2 py-4 pr-4 text-right sm:w-4/12 sm:text-left lg:w-2/12",on:{"click":_vm.open}},[_c('div',{staticClass:"truncate text-xs sm:text-sm"},[_vm._v(" "+_vm._s(_vm.asset.file.type)+" ")]),_c('div',{staticClass:"truncate text-xs text-neutral-400"},[_vm._v(" "+_vm._s(_vm.humanReadableSize)+" ")])]),_c('div',{staticClass:"flex-grow hidden w-2/12 items-center p-2 py-4 lg:flex",on:{"click":_vm.open}},[(_vm.wasRecentlyUpdated)?_c('div',{staticClass:"mr-2 h-1 w-1 rounded-full bg-primary-600"}):_vm._e(),_c('div',{class:[
          'truncate text-xs',
          { 'text-neutral-400': !_vm.wasRecentlyUpdated } ]},[_vm._v(" "+_vm._s(_vm.$t("components.brandMarketing.assets.assetsRow.expireDate", { expireDate: _vm.expireDate, }))+" ")])]),(_vm.menuItems.length > 0)?_c('OverflowMenu',{staticClass:"hidden items-center justify-end p-2 py-4 pr-4 sm:flex",attrs:{"items":_vm.menuItems}}):_c('div',{staticClass:"hidden items-center justify-end p-2 py-4 pr-4 opacity-0 transition-all duration-300 group-hover:opacity-100 sm:flex"},[_c('a',{staticClass:"text-neutral-300 transition-all duration-200 hover:text-neutral-800",attrs:{"href":_vm.asset.file.url,"target":"_blank","download":""},on:{"click":function($event){$event.stopPropagation();}}},[_c('IconDownload')],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }